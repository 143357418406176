.MuiMenu-paper{
 background: $dark;
 color: $light;
}


.Bell-Notification--count.hide {
        visibility: hidden;
    }
.Bell-Notification--count.show {
        margin-left: -12px;
        margin-top: 0;
        position: absolute;
        font-weight: 600;
        font-size: 11px;
    }

.iconBell.hide {
    visibility: hidden;
}