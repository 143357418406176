.MuiAccordionSummary-content {

}

.MuiAccordionSummary-expandIconWrapper{
  color: $light;
  transform: rotate(90deg);
}
.MuiAccordion-root {
  width: 100%;
  padding: 0;
  border: solid;
  border-radius: 0;
  background: transparent;
  color: white;

  .MuiAccordionSummary-root {
    text-transform: uppercase;
  }

  .MuiAccordionSummary-content {
    &.Mui-expanded {
      margin: 12px 0 0 0;
    }
  }

  &:first-of-type, &:last-of-type {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
