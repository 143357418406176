.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .wrapper__breadcrumb {
    margin: 0 auto;
    padding: 5px 15px;
    display: flex;
    border-bottom: 1px solid #3c3c3c;
    background: #222222;
    font-size: 18px;  

    .breadcrumb__item {
      display: flex;

      &::before {
        margin-right: 8px;
        margin-left: 8px;
        display: block;
        font-size: 20px;
        content: '/';
      }

      &:first-child::before {
        display: none;
      }
    }
  }

  .layout-alerts {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .layout-alert {
      min-height: 40px;
      max-width: 450px;
      min-width: 300px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      background-color: $primary;
      color: black;
      font-weight: bold;
      overflow: hidden;

      &:first-child {
        margin-top: 0;
      }

      .alert__message {
        padding: 8px 16px;
        font-size: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .alert__close {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        line-height: 15px;
        cursor: pointer;

        &:hover {
          background-color: darken($primary, 15%);
        }
      }
    }
  }

  .layout-header-menu {
    max-width: 300px;

    .MuiMenuItem-root {
      .handle-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .layout-bg {
    height: 100%;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: -1;
    background-image: url('../images/back.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 0.4;
  }

  .body-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > div {
      width: 100%;
      flex-grow: 1;
    }
  }

  header {

    .nav-link.disabled {
      color: #787676 !important;
    }

    .form-select {
      option {
        color: black;
      }
    }

    .avatar-image {
      img {
        border-radius: 50%;
        background-color: black;
      }
    }
  }
}

.drawer-main-navigation {
  .MuiPaper-root {
    max-width: 200px;
  }

  .MuiMenuItem-root {
    .handle-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@media (max-width: 768px) {
  .layout-wrapper {
    header {
      .header-user-item {
        padding: 0 !important;
      }
      .btn-open-drawer {
        margin-right: 0;
      }
    }
  }
}
