.App {
  .notMargins {
    margin: 0;
    padding: 0;
  }

  .MuiAccordion-root {
    margin: 16px 0;
    border: 1px solid #4d4d4d;
    border-radius: 1rem !important;
    
    @media only screen and (min-width: 767px){
      border: 0;
    }


    .MuiAccordionSummary-root {
      min-height: 48px;
      padding: 0;
      border-radius: 1rem;
      background-color: #000;
      color: var(--bs-orange);
      font-size: 1rem;

      @media only screen and (min-width: 767px){
        display: none;
      }

      .MuiAccordionSummary-expandIconWrapper {
        .MuiSvgIcon-root {
          height: 3rem;
          width: 4rem;
          transform: rotate(-90deg);
        }
      }

      .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(270deg);
      }
    }

    .MuiAccordionSummary-root.Mui-expanded {
      border-radius: 1rem 1rem 0 0;
      border-bottom: 0.25rem solid var(--bs-orange);
    }

    .MuiAccordionSummary-content {
      margin: 0;
      justify-content: center;

      p { 
        margin: 0 auto;
        margin-right: 4rem;
        font-size: 1.25rem;

        @media screen and (min-device-width: 570px) and (max-device-width: 1200px) {
          margin-right: 0;
        }
      }
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }
  }

  .DashboardFilter {
    padding: 1rem;
    border-radius: 1rem;

    .h1,
    h1 {
      text-align: left;
      .MuiSvgIcon-root {
        margin-left: -13px;
        margin-top: -3px;
        color: #ff8830;
        font-size: 2rem;
      }
    }

    .MuiFormControlLabel-root {
      span {
        margin: 0;
        padding: 0;
      }
    }

    .MuiPickerStaticWrapper-root {
      min-width: 100%;
      background-color: transparent;

      .MuiCalendarPicker-root {
        height: 276px;
        width: 85%;
        margin: 0;
        overflow: hidden;
      }

      .MuiButtonBase-root {
        height: 22px;
        color: var(--bs-white);

        .MuiSvgIcon-root {
          color: var(--bs-white);
        }
      }

      .MuiTypography-caption {
        width: 32px;
        color: var(--bs-orange);
        font-weight: 600;
      }
    }

    .MuiCheckbox-root:not(.Mui-checked) {
      color: #fff;
    }

    .Dashboard-filter__datepicker {
      width: 100%;
      display: flex;
      flex-flow: column;

      .MuiFormControl-root {
        .MuiInputLabel-root {
          color: var(--bs-white);
        }
        .MuiOutlinedInput-input {
          color: var(--bs-white);
        }
      }
    }
  }

  .DashboardFilter_actions .btn-primary {
    padding: 0.5rem 2rem;
    border-color: var(--bs-orange);
    background-color: var(--bs-orange);
    color: var(--bs-dark);
    &:hover {
      background-color: #ffa826;
    }
  }

  .left_bar-container {
    max-width: 338px;
    margin: 20px auto;
    top: 40px;
    position: sticky;

    @media only screen and (min-width: 767px) {
      margin: 0;
    }
  }

  .dashboard-card {
    padding: 0 3rem;
  }
}

.MenuOfLinks {
  display: flex;
  flex-direction: column;
  text-align: left;

  .MenuOfLinks__sub {
    margin-left: 1rem;

    .title {
      span {
        margin-left: -7px;
        padding: 5px;
        font-weight: 600;
        line-height: 0;
      }

      input,
      svg {
        visibility: hidden;
      }
    }

    .MuiFormControlLabel-root {
      width: 100%;
    }

    @media screen and (min-device-width: 200px) and (max-device-width: 1250px) {
      span {
        font-size: 10px;
      }
    }
  }

  .title {
    span {
      margin-left: -7px;
      padding: 5px;
      font-weight: 600;
      line-height: 0;
    }

    input,
    svg {
      visibility: hidden;
    }
  }

  .title-normal {
    span {
      font-weight: 600;
      line-height: 0;
    }
  }

  .menuLink {
    padding: 0.5rem 11px;
    border: 1px solid #4d4d4d;
    background: black;
    color: #fff;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      background: var(--bs-yellow);
      color: #000;

      [data-testid='AddIcon'] {
        color: #6c6c6c;
      }
    }
  }

  .active {
    background: var(--bs-yellow);
    color: #000;

    [data-testid='AddIcon'] {
      color: #fff;
    }
  }

  .menuLink--last {
    border-radius: 0 0 12px 12px;
  }

  .menuLink.menuLink--black-rounded {
    border-radius: 0 0 0.8rem 0.8rem;
    background: #000;
    color: #fff;
    font-size: 1.25rem;
    text-align: center;

    @media only screen and (min-width: 767px) {
      border-radius: 0;
    }
  }

  .MuiSvgIcon-root[data-testid='ArrowForwardIosIcon'] {
    right: 16px;
    position: absolute;
  }

  .MuiSvgIcon-root[data-testid='AddIcon'] {
    color: var(--bs-orange);
  }
}

.MenuOfLinks__style1 {
  border-radius: 0 0 0.68rem 0.68rem;
  background-color: var(--bs-gray-500);

  .MuiFormControlLabel-root {
    margin: 0;
  }
}

.App .MuiCalendarPicker-viewTransitionContainer {
  overflow: hidden;
}
