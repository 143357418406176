.ScheduleIndex {
    padding: 2rem 0;

    .ScheduleIndex__monitor--heading {
        margin: 1rem 0;

        svg {
            padding: 0.25rem;
            border-radius: 50%;
            background-color: var(--bs-orange);
            color: #000000;
            font-size: 4rem;
        }
    }

    .MuiCheckbox-root {
        color: #fff;
    }

    .ScheduleIndex__monitor {
        margin: 0 3rem;
        padding: 1rem;
        border-radius: 1rem;
        background-color: rgb(235 235 235 / 75%);

        .ScheduleIndex__navigation--dates {
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 991px){
                flex-flow: column;
            }

            .ScheduleIndex__navigation--dates-actions {
                width: 33%;
                display: flex;
                flex-flow: row;
                align-items: center;

                span {
                    width: 100%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: baseline;
                }
            }

        }

        .ScheduleIndex__monitor--left-actions {
            margin-top: 1rem;
            flex: 0 0 28%;
            text-align: start;

            @media (max-width: 991px){
              width: 100%;
            }

            span {
                width: 60%;
                display: inline-flex;
                align-items: baseline;
                color: var(--bs-dark);
                font-weight: 600;
                font-size: 10px;

                .MuiOutlinedInput-root {
                    height: 36px;
                    margin-left: 4px;
                }

                label {
                    margin: 1px;
                }

                div {
                    padding: 0;
                    font-size: 10px;
                    text-align: center;
                }
            }
        }

        .ScheduleIndex__monitor--center-actions {
            height: 40px;
            display: flex;
            align-items: center;
            flex: 0 0 39%;
            border-radius: 2rem;
            background-color: var(--bs-orange);
            color: var(--bs-dark);

            a {
                padding: 1rem;
            }

            button {
                min-height: 0;
                min-width: 0 !important;
                margin: 1rem;
                padding: 1px;
                border-radius: 50%;
                background-color: var(--bs-dark);
            }
        }

        .ScheduleIndex__monitor--right-actions {
            flex: 0 0 30%;
            text-align: end;

            @media (max-width: 991px){
              margin-top: 1rem;
            }

            span {
                display: inline-flex;
                align-items: baseline;
            }
        }

        .ScheduleIndex__navigation--dates-display {
            height: 3rem;
            max-width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            border-bottom: 1px solid var(--bs-dark);
            border-top: 1px solid var(--bs-dark);

            @media (max-width: 991px){
              display: none;
            }

            .day {
                margin: 0 1.25rem;
                font-weight: 600;
                line-height: 1.25;
                text-decoration: none;

                span {
                    display: block;
                    color: var(--bs-dark);
                    font-size: 13px;
                }
            }
        }

        .ScheduleEvents {
          margin-top: 2rem;
          justify-content: center;

            .col {
                max-width: 105px;
            }

            .MuiAccordion-root {
                margin: 1rem 0;
                padding: 0.2rem;
                border: none;
                box-shadow: none;

                .MuiAccordionSummary-root {
                    background-color: var(--bs-white);
                    color: var(--bs-dark);
                    font-size: 10px;

                    svg {
                        height: 19px;
                        width: 29px;
                        color: #000;
                    }

                    .MuiAccordionSummary-content {
                        p {
                            margin: 0 auto;
                            margin-left: 2rem;
                            font-size: .7rem;
                        }
                    }
                }
            }
        }
    }
}
