.fundraiser {
  height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 2px solid $primary;
  border-radius: 8px;
  overflow: hidden;

  .fundraiser__bg {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    background-image: url('../../images/campaignDefault.png');
    background-size: cover;
    background-position: 50%;
  }

  .fundraiser__header {
    padding: 16px;
    display: flex;
    justify-content: space-between;

    .fundraiser__time {
      display: flex;
      flex-direction: column;

      .time__item {
        margin-bottom: 8px;
        padding: 4px;
        display: flex;
        border-radius: 4px;
        background-color: $primary;
        overflow: hidden;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .time__type,
      .time__date,
      .time__time {
        width: 100px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        font-weight: bold;
        line-height: 20px;
        font-size: 18px;
      }

      .time__date,
      .time__time {
        border-left: 3px solid black;
      }
    }

    .fundraiser__progress {
      .CircularProgressbar {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        background-color: transparentize(black, 0.35);

        .CircularProgressbar-path,
        .CircularProgressbar-text {
          stroke: $primary;
        }
      }
    }
  }

  .fundraiser__body {
    display: flex;
    flex-grow: 1;
  }

  .fundraiser__footer {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%);

    .footer__left,
    .footer__right {
      display: flex;
      align-items: center;
    }

    .fundraiser__owner {
      margin-right: 8px;

      .owner__avatar {
        height: 64px;
        width: 64px;
        border: 2px solid $primary;
        border-radius: 50%;
        background-image: url('../../images/campaignDefault.png');
        background-size: cover;
        background-position: 50%;
      }
    }

    .fundraiser__details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .fundraiser__name {
        color: $primary;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .fundraiser__participants {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .participants__title {
        margin-bottom: 4px;
      }
    }
  }
}
