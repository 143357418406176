.highlights-category_item {
  width: 100%;
  padding: 10px;
  border: 1px solid #3b3b3b;
  border-radius: 5px;
  overflow: hidden;
  background: #3b3b3b;

  * {
    word-break: break-word;
  }

  img {
    height: 200px;
    max-width: 100%;
    object-fit: contain;
    word-break: normal;
  }

  > a {
    padding: 12px;
    display: flex;
    flex-direction: column;
    border: 1px solid #323232;
    border-radius: 8px;
    background: #000;
    color: white;
    text-align: left;
    text-decoration: none;
  }

  h3 {
    margin: 10px 0;
    color: $primary;
    font-size: 24px;
  }

  p {
    margin-bottom: 8px;
  }

  a.pdf {
    align-items: center;

    img {
      height: 90px;
      width: 90px;
    }
  }
}

.highlights-category__actions {
  margin: 10px 0 20px;
  display: flex;
  gap: 15px;

  > * {
    flex: 1;
    border: 1px solid;
    text-align: center;
    text-decoration: none;
  }
}

.highlights-detail {
  font-weight: normal;
  text-align: left;

  h2 {
    margin: 0 0 20px;
    color: $primary;
    font-weight: bold;
    font-size: 26px;
  }

  h4 {
    color: grey;
    font-size: 16px;
  }

  h3 {
    font-weight: normal;
    font-size: 17px;
    word-break: break-all;

    span.badge {
      background: #323232;
      color: rgb(200, 200, 200);
    }
  }

  &__form {
    .content-uploaded {
      .pdf {
        width: fit-content;
        margin-bottom: 20px;
        padding: 15px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        gap: 10px;
        background: white;
        color: black;
        text-decoration: none;

        img {
          width: 40px;
        }
      }

      img {
        max-width: 300px;
      }
    }

    fieldset {
      margin-bottom: 10px;

      label {
        margin-bottom: 5px;
        color: #939393;
        font-weight: 600;
        font-size: 13px;
        text-transform: uppercase;
      }
    }
    input,
    textarea,
    select {
      width: 100%;
      padding: 5px 15px;
      border: none;
      border-radius: 5px;
      background: rgb(56, 56, 56);
      color: white;
      box-shadow: none;
    }
  }
}
