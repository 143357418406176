.DashboardEvents {
    margin: 5rem;
    padding: 1rem 3rem 0 3rem;
    border-radius: 2rem;
    background-color: #cac8c875;
    background-image: url('../../images/events_bg-min.jpg'), linear-gradient(180deg, rgba(201,201,201,0.7371323529411764) 0%, rgba(3,3,3) 100%);
    background-size: cover;
    background-position-y: top;
    background-position-x: 83%;
    background-blend-mode: multiply;

    @media (max-width: 1200px) {
        margin: 1rem 1.5rem;
    }

    @media (max-width: 555px){
        margin: 1rem -1rem;
        padding: 1rem 1rem 0 1rem;
        border-radius: 0;
    }

    .swiper {
        width: 100%;
        margin: 0;
        padding-top: 1rem;

        .swiper-slide {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .MuiSvgIcon-root {
                margin: 0.1rem;
                padding: 0.5rem;
                border: 2px solid var(--bs-orange);
                border-radius: 50%;
                background: rgb(0, 0, 0);
                color: var(--bs-white);
                font-size: 5rem;
            }   

            .title {
                color: var(--bs-orange);
                font-size: .8rem;

                @media (max-width: 1200px) {
                    font-size: .6rem;
                }
            }

            .money {
                font-weight: 600;
                font-size: 1.5rem;

                @media (max-width: 1200px) {
                    font-size: .7rem;
                }
            }
        }
    }


    .DashboardEvents_date{
        .datetag {
            height: 35px;
            width: 169px;
            left: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 4px;
            background-color: var(--bs-orange);
            color: #000;
            font-weight: 700;
            line-height: 1;
            font-size: 14px;
    
            span {
                padding: 0.5rem;
                flex: 0 0 33%;
            }
    
            .first {
                border-right: 2px solid var(--bs-dark);
            }
    
            .last {
                padding: 0;
                border-left: 2px solid var(--bs-dark);
            }
    
        }
    }
    


    .DashboardEvents_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .DashboardEvents_footer--featured {
            display: flex;
            align-items: center;

            @media (max-width: 1200px) {
                margin-left: 0.5rem;
            }

            h5 {
                color: var(--bs-orange);

                @media (max-width: 1200px) {
                    font-size: .7rem;
                }
            }
        }
    
        .DashboardEvents_footer--featured--action {
            flex-direction: column;

            .MuiSvgIcon-root {
                margin: 0.1rem;
                margin-left: -7px;
                padding: 0.5rem;
                border: 2px solid #ff8830;
                border-radius: 50%;
                background: #fff;
                color: var(--bs-orange);
                font-size: 2rem;

                @media (max-width: 1200px) {
                    font-size: 1.7rem;
                }
            }

            .title {
                margin-left: -11px;

                @media (max-width: 1200px) {
                    font-size: .6rem;
                }
            }
        }
    
        .DashboardEvents_footer--featured--image svg {
            margin-right: .5rem;
            padding: 0.5rem;
            border: 2px solid var(--bs-orange);
            border-radius: 50%;
            background-color: #000;
            font-size: 4rem;

            @media (max-width: 1200px) {
                font-size: 2rem;
            }
        }

    }

}