.DashboardChart {
    margin: 0 4rem;
    padding: 1rem;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-radius: 1rem;

    .MuiSelect-select, .MuiSelect-select:focus {
        padding: .5rem 1rem;
        border: .2rem solid var(--bs-orange);
        border-radius: 1rem;
        background-color: #2e2d2d;
        color: #fff;

        .MuiSvgIcon-root, svg.MuiSvgIcon-root {
            color: #fff !important;
            fill: #fff;
        }

    }

    .canvas_container {
        width: 100%;
        flex: 0 0 75%;
        canvas {
            height: 42vh !important;
            max-height: 250px;
        }

    }

    .MuiInputLabel-root {
        visibility: hidden;
    }

    .DashboardChart__actions {
        flex-flow: row;
        justify-content: center;
        align-items: baseline;
        flex: 0 0 25%;

        @media (max-width: 767px) {
          margin: auto;
        }
    }
}

.DashboardChart.DashboardChart__leftside {
    margin: auto;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    border: transparent;
    border-radius: 1rem;
}

svg.MuiSvgIcon-root[data-testid="ArrowDropDownIcon"] {
    color: #fff;
}
