//Display Types
.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.flex-wrap {
  flex-wrap: wrap;
}

.mt-mobile {
  @media (max-width: 767px) {
      margin-top: 2.5rem;
  }
}

.refactor-align-item {
  margin-left: 2rem;
  justify-content: flex-start !important;
}

.refactor-align-item-inverter {
  margin-left: -2rem;
}

.skeleton-btn-chart-type1 {
  margin: auto auto -60px 5rem !important;

  @media (max-width: 767px){
    margin: auto auto -60px calc(3.5rem - 1vw) !important;
  }
}

@media screen and (min-device-width: 767px) and (max-device-width: 991px) {
  .font-tablet {
    font-size: 10px;
  }
}

.tag-comingsoon {
  margin-left: -33px;
  position: absolute;
  font-size: 9px;
}

.MuiDrawer-paper .tag-comingsoon {
    margin-left: 0;
    position: absolute;
    font-size: 9px;
}

.MuiSkeleton-root {
  border-radius: 1rem;
  background-color: rgb(155 154 154 / 90%) !important;
}

.btn-primary {
  background-color: var(--bs-orange);

  &:hover {
    background-color: var(--bs-yellow) !important;
  }
}

.v-hidden {
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.swiper-pagination {
  position: initial;
}

.App {

  .btn-outline-back--white {
    border-radius: 2rem;
    background-color: var(--bs-white);
    color: var(--bs-blue);
    font-size: 9px;
  }

  .btn-outline-action {
    border: 1px solid var(--bs-blue);
    border-radius: 2rem;
    color: var(--bs-blue);
    font-size: 9px;
  }

  .btn-search-type1 {
    min-width: 0;
    margin: auto 0.5rem;
    padding: 0.25rem;
    border-radius: 50%;
    background-color: var(--bs-dark);
  }

  .mr-dot-5 {
    margin-right: 0.5rem;
  }

}



@media (max-width: 767px){
  .container {
    padding-right: 0;
    padding-left: 0;
  }
}

.h-auto {
  height: auto !important;
}


.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.table-row-3 {
  span:not(.badge) {
    width: 33%;
    text-align: left;
  }
}

.table-row-5 {
  span:not(.badge) {
    width: 25%;
    text-align: left;
  }
}

.table-row-6 {
  span:not(.badge) {
    width: 16%;
    text-align: left;
  }
}
