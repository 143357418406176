.Welcome {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .background {
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    opacity: .4;
    object-fit: cover;
  }

  .country-code {
    @media(min-width: 992px) {
      max-width: 110px;
    }

    .MuiAvatar-root {
      height: 32px;
      width: 32px;
      top: -5px;
    }
  }

  .MuiInput-underline .input {
    &:before {
      border-bottom-color: pink;
    }
  }

  .MuiListItem-root {
    justify-content: center;
    align-items: center;
  }

  .welcome-logo {
    height: auto;
    width: 80%;
    max-width: 350px;
  }

  .welcome-logo-influencers {
    height: auto;
    width: 50%;
    max-width: 200px;
  }

  .welcome-logo-venues {
    height: auto;
    width: 50%;
    max-width: 165px;
    margin-top: 2rem;
  }
}
