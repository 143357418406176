.App {

    .VenuesFeaturedArtistsCard, .VenuesFeaturedArtistsCard .card-body {
        max-width: 685px;
        margin-bottom: 1rem;
        padding: 0;
        border: 0;
        border-radius: 1rem;
        background: transparent;

        .uppercase {
            text-transform: uppercase;
        }

        .collapse {
            background-color: #8080806b;
        }

        .VenuesFeaturedArtistsCard__ProfileArtistsImage {
            .VenuesFeaturedArtistsCard__ProfileArtistsPicture {
                border: 3px solid var(--bs-orange);
                border-radius: 50%;
                background-color: var(--bs-white);
            }
        }

        .VenuesFeaturedArtistsCard__ProfileArtistsPicture {
            width: rem(68px);
        }

        .VenuesFeaturedArtistsCard__ProfileArtistsPicture--Tags {
            margin: auto;
        }

        p {
            margin-left: 2rem;
        }

        .badge {
            border-radius: 1rem;
        }

        .list-group {
            .list-group-item {
                border: none !important;
            }
        }

        .VenuesFeaturedArtistsCard__ProfileArtistsParagraph p, .VenuesFeaturedArtistsCard__ProfileArtistsParagraph span {
            margin: 0 !important;

            @media (max-width: 767px){
                font-size: calc(1.2rem - 1.5vw);
            }
        }

        .VenuesFeaturedArtistsCard__ProfileArtistsParagraph {
            line-height: 1;
            text-align: left;
        }

        .VenuesFeaturedArtistsCard__ProfileArtists--Blank-Divided {
            p {
                margin: 0 2rem;
            }
            svg {
                margin-top: -5px;
            }

            svg[data-testid="PushPinIcon"] {
                transform: rotate(45deg);
            }
        }
    }

    .badge.bg-dark {
            border: 1px solid var(--bs-orange);
    }

    .VenuesFeaturedArtistsCard .card-body {
        background-color: #ffffffbf;
    }

    .VenuesFeaturedArtistsCard--title {
        margin: auto;
        background-color: transparent;

        @media (max-width: 767px){
            margin-top: 2rem;
        }

        h1 {
            margin: auto;
            line-height: .95;
            font-size: calc(rem(38px) + 1vw);
            text-shadow: 0 1px 7px var(--bs-orange);

            @media (max-width: 767px){
                margin: 0 1rem;
                line-height: 1;
                font-size: calc(1.2rem - 1.5vw);
            }
        }
    }

    .VenuesFeaturedArtistsCard__col {
        .list-group {
            background-color: transparent;
            .list-group-item {
                padding: 0 1rem;
                background-color: transparent;

                span {
                    @media (max-width: 767px){
                        font-size: calc(rem(8px) + 1vw);
                    }
                }
            }
        }
    }

}
