.App {

    img.icon {
        width: 30px;
        margin-right: 0.5rem;
    }

    .CardDetailsVenuesType1 {
        padding: 1rem;
        border-radius: 4px;
        background-color: rgb(43 43 43);

        a {
          color: #367bf9;
        }

        .Shedule-list--title{
            width: 80px;
            margin-right: .5rem;
            font-weight: 600;
            font-size: 13px;
            text-align: left;
        }

        .hours_tag {
            font-weight: 600;

            button {
              color: #000;
              &:hover {
                color: #367bf9 !important;
              }
            }
        }

        .CardDetailsVeneusType1-heading {
            text-align: left;

            > p{
                margin: 0;
                padding: 7px 0;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #4b4b4b;
                font-size: 14px;

                > button{
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                }
            }
        }

        .CardDetailsVeneusType1-body {
            margin-top: 20px;
            padding: 0;
            border: 1px solid #4b4b4b;
            border-radius: 4px;
            border-collapse: collapse;
        }

        .Shedule-list--all-items {
            width: 75%;
            margin: 0;
            font-size: 14px;

            p {
                width: 100%;
                margin: 0;
                font-size: 15px;
            }
        }

        .Shedule-item {
            padding: 4px 10px;
            align-items: center;

            &:nth-child(odd){
                border-color: #4b4b4b;
                border-bottom: 1px solid;
                border-top: 1px solid;
                background: #1c1c1c;
            }

            &:last-child {
                border: none;
            }
        }


        .CardDetailsVeneusType1-heading__socials {
            text-align: left;

            .CardDetailsVeneusType1-heading__socials__list {
              margin: 1rem 0;
              a {
                display: block;
                color: var(--bs-dark);
                text-decoration: none;
              }
            }

            h5 {
                margin: 0;
                color: var(--bs-yellow);
            }

            svg {
                color: var(--bs-white);
            }
        }

    }

    .VenuesMainBannerType1 {

        .VenuesMainBannerType1__wrapper {
            display: flex;
            flex-flow: row;

            @media (max-width: 991px) {
                flex-wrap: wrap;
            }

            .VenuesMainBannerType1__wrapper--logo {
                padding: 10px 20px 0;
                position: relative;
                display: flex;
                gap: 15px;

                .VenuesMainBannerType1__wrapper--logo-floating-btn {
                    left: 6rem;
                    top: -60px;
                    position: absolute;

                    .btn__mode-edit {
                      border-radius: 10px;
                      background-color: rgb(0 0 0 / 41%);
                      font-size: 37px;
                    }
                }
            }

            .VenuesMainBannerType1__wrapper--tags {
                padding: 2rem;
                flex: 1;
                text-align: end;

                @media (max-width: 767px) {
                    padding: 2rem 0;
                    flex: 0 0 100%;
                }

                .VenuesMainBannerType1__wrapper--tag {
                    min-width: calc(2rem + 1vw);
                    margin: 0 0.45rem;
                    padding: 0.1rem;
                    border: 1px solid var( --bs-orange);
                    border-radius: 2rem;
                    color: var(--bs-orange);
                    font-weight: 600;
                    font-size: 10px;
                    text-align: center;


                }
            }

        }

        .VenuesMainBannerType1__image--background {
            border: 1px solid #3c3c3c;
            border-top: 0;
            background: black;
        }
        .VenuesMainBannerType1__image--background-Tag {
            height: 250px;
            max-height: 250px;
            object-fit: contain;
        }

        .VenuesMainBannerType1__image--background-floating-btn {
            margin-left: -4.3rem;
            margin-top: 0.5rem;
            position: absolute;

            .btn__mode-edit {
              border-radius: 10px;
              background-color: rgb(0 0 0 / 41%);
              font-size: 37px;
            }
        }

        .VenuesMainBannerType1__wrapper--logo-image {
            height: 6rem;
            width: 6rem;
            border: 3px solid var(--bs-orange);
            border-radius: 50%;
            background-color: var(--bs-dark);
            object-fit: cover;
            transform: translateY(-40px);
        }

        .VenuesMainBannerType1__wrapper--container {

            .VenuesMainBannerType1__wrapper--title {
              margin-bottom: 0;
              margin-top: 5px;
              color: var(--bs-orange);
              font-size: calc(2.5rem - 1vw) !important;

                @media (max-width: 767px) {
                    font-size: calc(1.5rem - 1vw) !important;
                }
            }

            .VenuesMainBannerType1__wrapper--subtitle {
                font-size: 14px;
            }

        }

    }

    .Veneus_description {
        margin-bottom: 1.5rem;  
        padding: 0 1rem;
        border: 1px solid var(--bs-orange);
        border-radius: 5px;
        text-align: left;

        h4 {
            margin-bottom: 0;
            color: var(--bs-orange);
            font-size: 1.25rem;
        }
    }

    .Venues_container-special {
        h4 {
            margin: 0 0 10px;
            color: var(--bs-orange);
            text-align: start;
            text-transform: capitalize;


            @media screen and (max-width: 767px) {
                text-align: start;
            }
        }
    }
}
