.main-navigation {
  border-bottom: solid 2px $primary;

  .nav-link {
    font-weight: lighter;
    text-transform: uppercase;

    &.selected {
      color: $primary;
    }
  }

  .navigation-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 0;
  }

  .navigation-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;

    ul {
      margin: 0;
      display: flex;
      gap: 20px ;

      > li {
        padding: 0;
        text-align: center;
          a {
            padding: 0;
            color: $light;
            text-decoration: none;
          }
      }
    }
  }

  .navigation-end {
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 0;

    @media screen and (max-width: 767px) {
      justify-content: flex-end;
    }
  }

  .brand {
    height: 60px;
    width: 60px;
  }

  .stage-name {
    margin-left: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .avatar-holder {
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar-name {
      max-width: 80px;
      margin-left: 12px;

      @media screen and (max-width: 767px) {
        display: none !important;
      }

      span {
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      select {
        padding: 0px 32px 0px 0;
        border: none;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-position: right 0.5rem center;
        color: white;
        cursor: pointer;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.drawer-main-navigation {
  .MuiDrawer-paper {
    min-width: 280px;
    padding: 1rem;
    background: $dark;
    color: $light;
  }

  .drawer-main-navigation-header {
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: solid thin $primary;
  }

  .btn-open-drawer {
    margin: 0;
  }

  .brand {
    width: 100%;
    max-width: 140px;
  }

  ul {
    padding-left: 0;

    li {
      padding: 0;

      &:hover {
        background-color: rgba(255, 255, 255, 0.075);
      }
    }

    a {
      width: 100%;
      margin: 0;
      padding: 1rem;
      color: $light;

      &:hover {
        color: $text-color-light;
      }
    }
  }
}

.MuiMenu-paper.MuiPopover-paper {
  border: solid thin $primary;
  background: $dark;
  color: $light;
  overflow: visible;

  ul {
    padding: 0;

    li:not(.MuiListSubheader-root) {
      min-width: 280px;
      margin: 0;
      padding: 5px;
      border-top: solid thin $border-color-dark;  

      &:hover {
        background-color: rgba(255, 255, 255, 0.075);
      }

      &.menu-header {
        padding: 6px 32px;
        background-color: $border-color-dark;
      }
    }

    li.MuiListSubheader-root {
      background-color: $border-color-dark;
      color: white;
    }
  }

  &:before {
    height: 20px;
    width: 20px;
    left: 50%;
    top: 1px;
    position: absolute;
    z-index: 0;
    display: block;
    border-left: solid thin $primary;
    border-top: solid thin $primary;
    background-color: $border-color-dark;
    transform: translate(-50%, -12px) rotate(45deg);
    content: '';
  }

  .MuiListItem-root,
  .MuiMenuItem-root {
    .MuiTypography-root {
      margin: 0;
      color: $light;
      &.MuiListItemText-secondary {
        color: $light-opacity;
      }
    }
  }
}

@media (max-width: 425px) {
  .main-navigation {
    .avatar-holder {
      .avatar-name {
        max-width: 100px;
      }
    }
  }
}
