.App {

    .MainPictureDisplayer__slider {
        border: 1px solid var(--bs-orange);
        border-radius: 1rem;

        @media (max-width: 767px) {
            width: 100% !important;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid var(--bs-white);
            border-top: 1px solid var(--bs-white);
        }

        .MainPictureDisplayer__slider--image {
            @media (min-width: 768px) {
                padding: 3rem;
            }
            cursor: pointer;
        }

        .swiper-button-next {
            right: 9px !important;
        }

        .swiper-button-prev {
            left: 9px !important;
        }
    }

    .MainPictureDisplayer__description {
        margin: 0 3rem;
        text-align: left;

        h2 {
            padding-bottom: 0.5rem;
            border-bottom: 1px solid var(--bs-orange);
            color: var(--bs-orange);
            font-size: 1.25rem;
        }

        p {
            font-size: 1rem;

            img.icon {
                width: 19px;
            }
        }
    }

    .GalleriesOfPicturesDisplayer {
        padding: 1rem;

        .btn-primary {
            width: 100%;
            border-color: #FF9900;
            background-color: #FF9900;
            color: #000;
        }

        .GalleriesOfPicturesDisplayer__heading {
            width: 100%;
            margin-top: -2.5rem;
            border-color: #FF9900;
            border-radius: 2rem;
            background-color: #FF9900;
            color: #000;
        }

        .GalleriesOfPicturesDisplayer__images {

            .swiper-wrapper {
                display: flex;
                flex-flow: wrap;
                gap: 1rem;

                @media (max-width: 767px) {
                    justify-content: center;
                }

                .GalleriesOfPicturesDisplayer__images--slide {
                    width: 6rem !important;
                    border: 2px solid var(--bs-orange);
                    border-radius: 1rem;
                    line-height: 5.2;
                    cursor: pointer;

                    @media (max-width: 991px){
                        height: calc(2rem + 1vw);
                        width: calc(3rem + 1vw) !important;
                        border: 2px solid var(--bs-orange);
                        border-radius: 1rem;
                        line-height: 2.2;
                    }
    
                    img {
                        margin-top: -7.5px;
                        border-radius: 0.9rem;
                        object-fit: cover;
                    }
                }

                .swiper-slide-thumb-active {
                    border-color: #ffc107 !important;
                }

            }
        }

        .GalleriesOfPicturesDisplayer__images--slide-video {
            background: var(--bs-white);

            @media (max-width: 767px){
                line-height: 1.9 !important;

                a {
                    padding: 0 !important;
                }
            }

            .btn-video {
                padding: 36%;
            }
        }

        .GalleriesOfPicturesDisplayer__actions {
            margin: 2rem 0;

            .GalleriesOfPicturesDisplayer__actions--tags {
                margin: 1rem;

                .GalleriesOfPicturesDisplayer__actions--tags-tag {
                    margin: 0.5rem;
                    padding: 0.25rem 0.5rem;
                    border: 3px solid var(--bs-orange);
                    border-radius: 2rem;
                    color: var(--bs-orange);
                    font-weight: 800;
                    font-size: 11px;
                }
            }
        }

        .GalleriesOfPicturesDisplayer__actions--margin-top {
            margin-top: 5rem;
        }
    }

}