.SelectNGO {
  position: relative;

  .form-select {
      width: 100%;
      margin-right: 8px;
      border: none;
      border-radius: 0;
      border-bottom: 2px solid white !important;
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
      background-repeat: no-repeat !important;
      background-size: 13px !important;
      background-position: right !important;
      color: white;

      &:focus {
          background-color: transparent;
          color: white;
          box-shadow: none;
      }
  }

  .SelectNGO__list {
      width: 100%;
      max-height: 9rem;
      margin-top: -2px;
      position: absolute;
      z-index: 1;
      background-color: #fff;
      text-align: left;
      overflow: auto;

      button {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          border-radius: 0;
          color: #000;

          &:hover {
              background-color: var(--bs-orange);
          }
      }

      &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: rgb(223, 223, 223);
        }

        &::-webkit-scrollbar-thumb {
          border: 3px solid var(--bs-orange);
          border-radius: 20px;
          background-color: var(--bs-orange);
        }
  }


  .SelectNGO__list.hidden {
      display: none;
  }

  .SelectNGO_btn-clear {
      right: 21px;
      top: 4px;
      position: absolute;
      cursor: pointer;
  }
}
