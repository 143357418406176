.Events-filter {
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: #333333;

  .Events-filter__submit {
    margin-top: 16px;

    &:focus {
      box-shadow: none;
    }
  }

  .Events-filter__selector {
    margin-bottom: 8px;
    display: flex;

    input {
      width: 100%;
      margin-right: 8px;
      border: none;
      border-radius: 0;
      border-bottom: 2px solid white;
      background-color: transparent;
      color: white;

      &:focus {
        background-color: transparent;
        color: white;
        box-shadow: none;
      }
    }

    select {
      width: 110px;
      min-width: 110px;
      margin-right: 8px;
      padding: 4px 32px 4px 8px;
      border: none;
      border-radius: 0;
      border-bottom: 2px solid white;
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-position: right 0.5rem center;
      color: white;
      cursor: pointer;

      option {
        color: black;
      }

      &:focus {
        background-color: transparent;
        color: white;
        box-shadow: none;
      }
    }

    .item-btn {
      font-weight: bold;
      line-height: 24px;
      font-size: 24px;
    }
  }

  .Events-filter__items {
    display: flex;
    flex-wrap: wrap;
  }
}
