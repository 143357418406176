.artist-pay-now {
    padding: 20px 0;

    input, textarea {
        width: 100%;
        margin-bottom: 20px;
        padding: 5px;
        border: 0;
        border-bottom: 1px solid;
        background: #2c2c2c;
        color: white;
        outline: 0;

        &::placeholder {
            color: grey;
        }

        &:focus{
            border-color: $primary;
            color: $primary;
        }
    }

    .cards{
        margin-bottom: 30px;

        h5{
            margin: 10px 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
        }
    }

    .search-listing {

        .heading {
            color: $primary;
            font-weight: 200;
            text-align: center;
        }

        .artist-list {
            width: 100%;
            max-height: 300px;
            top: calc(100% - 20px);
            position: absolute;
            z-index: 1;
            background: black;
            overflow: auto;

            .profile {
                --dimension: 50px;
                
                height: var(--dimension);
                width: var(--dimension);
                margin-right: 15px;
                flex-basis: var(--dimension);
                border-radius: var(--dimension);
                background: #414141;
                overflow: hidden;
            }

            ul {
                li {
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    flex: 1 1 220px;
                    border: 1px solid #404040;
                    cursor: pointer;

                    &.active {
                        border: 1px solid var(--bs-orange);
                        background: RGBA(0,0,0,.15);
                    }

                    h3 {
                        margin: 0;
                        font-size: 15px;
                        white-space: nowrap;
                        text-transform: capitalize;
                    }

                    h4 {
                        margin: 7px 0 0;
                        color: grey;
                        font-weight: 600;
                        font-size: 12px;
                    }

                    p {
                        margin: 0;
                        color: #b9b9b9;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }

        }
    }
}