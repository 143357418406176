

.MessengerIndex {

    @media (max-width: 767px) {
      .col-9 {
        width: 100%;
      }

      .col-3 {
        width: 100%;
      }
    }

  .MessengerIndex__container {
    padding: 0;
    border: 1px solid var(--bs-orange);
    border-radius: 1rem;
    background-color: #2125298f;

    .col-3 {
      height: 74vh;
      padding: 1rem;
      border-right: 1px solid var(--bs-gray-200);

      @media (max-width: 991px) {
        width: 100%;
        border-right: none;
      }

      .MessengerPeaopleList__items-person {
        padding: 0.5rem;
        display: flex;
        cursor: pointer;

        &:hover {
          border-radius: 0.5rem;
          background-color: var(--bs-gray-600);
        }

        img {
          max-width: 38px;
        }

        div {
          width: 100%;
          margin-left: 0.28rem;
          line-height: 1;
          text-align: end;

          @media screen and (min-device-width: 992px) and (max-device-width: 1400px) {
            width: 80%;
            text-align: center;
          }

          h3 {
            margin: 0;
            color: var(--bs-orange);
            font-size: 0.8rem;
            text-align: start;
          }

          p {
            width: 118px;
            max-height: 13px;
            margin: 0;
            font-size: 9px;
            white-space: nowrap;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;

            @media screen and (min-device-width: 992px) and (max-device-width: 1400px) {
              width: 100%;
            }
          }

          small {
            font-size: 8px;
          }
        }
      }

      .MessengerPeaopleList__searh-input__control {
        .MuiInputLabel-root {
          margin-top: 0.17em;
          line-height: 1.4375em;
        }

        .MuiInputBase-formControl {
          background-color: var(--bs-white);
        }

        .MuiOutlinedInput-input {
          height: 0.4375em;
        }

        .MuiInputAdornment-root {
          @media screen and (min-device-width: 992px) and (max-device-width: 1400px) {
            display: none;
          }
        }
      }
    }

    .col-9 {
      @media (max-width: 991px) {
        width: 100%;
      }
      .MessengerChatView {
        .MessengerChatView__header {
          display: flex;
          flex-flow: row;
          border-bottom: 1px solid var(--bs-gray-400);

          .MessengerChatView__header-person {
            width: 50%;
            padding: 0.5rem;
            display: flex;
            border-radius: 0.5rem;
            cursor: pointer;

            img {
              max-width: 38px;
            }

            div {
              width: 100%;
              margin-left: 0.28rem;
              line-height: 1;
              text-align: start;

              h3 {
                margin: 0;
                color: var(--bs-orange);
                font-size: 0.8rem;
                text-align: start;
              }

              p {
                width: 118px;
                max-height: 13px;
                margin: 0;
                font-size: 9px;
                white-space: nowrap;
                text-align: left;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              small {
                font-size: 8px;
              }
            }
          }

          .MessengerChatView__header-actions {
            width: 50%;
            display: inline-block;
            text-align: end;

            .MenssengerChartView__header-actions__button {
              margin-top: 0.5rem;
            }

            button {
              margin-right: 10px;
            }
          }
        }

        .MessengerChatView__body {
          height: 57vh;
          border-bottom: 1px solid;

          .MessengerChatView__body-person {
            width: 80%;
            padding: 0.5rem;
            display: flex;
            border-radius: 0.5rem;
            cursor: pointer;

            img {
              max-width: 38px;
            }

            div {
              width: 100%;
              margin-left: 0.58rem;
              padding: 0.5rem;
              border-radius: 0.5rem;
              background-color: var(--bs-gray-200);
              color: #000;
              line-height: 1;
              text-align: end;

              h3 {
                margin: 0;
                color: var(--bs-orange);
                font-size: 0.8rem;
                text-align: start;
              }

              p {
                width: 118px;
                max-height: 13px;
                margin: 0;
                font-size: 9px;
                white-space: nowrap;
                text-align: left;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              small {
                font-size: 8px;
              }
            }

            .you {
              background-color: var(--bs-orange);
              color: #fff;

              h3 {
                color: #fff;
              }
            }
          }
        }

        .MessengerChatView__footer {
          padding-right: 1rem;
          display: flex;
          justify-content: right;
          align-items: center;

          .MessengerChatView__footer-input {
            .MuiInputLabel-root {
              margin-top: 0.17em;
              line-height: 1.4375em;
            }

            .MuiFormControl-root {
              margin-bottom: 0;
            }

            .MuiInputBase-formControl {
              background-color: var(--bs-white);
            }

            .MuiOutlinedInput-input {
              height: 0.4375em;
              width: 310px;

              @media (max-width: 1300px) {
                width: 100%;
              }
            }
          }

          .MessengerChatView__footer-actions__button {
            min-height: 2rem;
            min-width: 2rem;
            margin: 0 0.38rem 0 0.69rem;
            padding: 10px;
            border-radius: 50%;
            background-color: var(--bs-white);
            color: var(--bs-dark);
          }
          .MessengerChatView__footer-actions__button--send {
            min-height: 2rem;
            min-width: 4rem;
            margin: 0 0.38rem 0 0.69rem;
            padding: 10px;
            border-radius: 1rem;
            background-color: var(--bs-orange);
            color: var(--bs-dark);
          }
        }
      }
    }
  }
}

.MessengerIndex__col {
  padding: 0 3rem;
}

.Messenger-actions.Messenger-actions-right {
  display: flex;
  justify-content: right;
  gap: 1rem;

    .Messenger-btn-upload {
        padding: 0.25rem 1.5rem;
        border-radius: 1rem;
        background-color: var(--bs-primary);
        color: #000;
        font-weight: 800;
        font-size: calc(4px + .5vw);
    }

    .Messenger-btn-create {
        padding: 0.25rem 1.5rem;
        border-radius: 1rem;
        background-color: var(--bs-secondary);
        color: #000;
        font-weight: 800;
        font-size: calc(4px + .5vw);
    }
}


.Messenger-actions.Messenger-actions-center {
  min-height: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  gap: 2rem;

  .Messenger-btn-share {
    display: flex;
    flex-flow: column;
    line-height: 1.25;

     svg {
      margin-bottom: 11px;
      padding: 0.75rem;
      border-radius: 50%;
      background-color: var(--bs-orange);
      color: var(--bs-dark);
      font-size: 5rem;
     }

     span {
       font-size: 16px;
     }
  }
}
