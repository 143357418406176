.Events-create {
  padding-bottom: 32px;
  padding-top: 32px;

  .Events-title-name {
    width: 100%;
    color: white;
    text-align: end;
  }

  .step-3__transactions {
    display: flex;
    flex-direction: column;
    text-align: left;

    p {
      text-align: justify;
    }
  }

  .step-3__sub-title {
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 8px;
    background-color: black;
  }

  .event__header {
    display: flex;
    flex-direction: column;

    .header__banner {
      height: 300px;
      width: 100%;
      margin-left: auto;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
    }

    .header__banner-details {
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
    }
  }

  .event__avatar {
    height: 100px;
    width: 100px;
    margin-top: -32px;
    padding: 20px;
    position: relative;
    border: 2px solid $primary;
    border-radius: 50%;
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    &.default-avatar {
      background-size: 60px 70px;
    }

    .image-crop-input {
      margin: 0;
      right: -14px;
      top: -12px;
      position: absolute;
    }
  }
}

@media (max-width: 576px) {
  .Events-create {
    .Events-title-name {
      text-align: center;
    }
  }
}
