.btn-link__dropdown {
  padding: 0;
  border: none;
  background-color: transparent !important;
  color: #fff;
  text-transform: uppercase;

  &:hover {
    padding: 0;
    border: none;
    background-color: transparent;
    color: #fff;
    text-transform: uppercase;
  }

  &:focus {
    padding: 0;
    border: none;
    color: #fff !important;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none !important;
  }
}

.dropdown-menu {
  padding: 0 !important;
  border: thin solid #fd7e14;
  background-color: #000;

  &:before {
    height: 20px;
    width: 20px;
    left: 50%;
    top: 1px;
    position: absolute;
    z-index: 0;
    display: block;
    border-left: solid thin #ff9900;
    border-top: solid thin #ff9900;
    background-color: #323232;
    transform: translate(-50%, -12px) rotate(45deg);
    content: '';
  }

  button {
    min-width: 280px;
    margin: 0;
    padding: 16px 24px;
    border-top: solid thin #323232;
    color: white;
    text-transform: uppercase;

    &:hover {
      background-color: rgba(255, 255, 255, 0.075);
      color: #fff;
    }
  }

  &.show {
    margin-top: 1rem;

    .tag-comingsoon {
      margin-top: 1rem;
      right: 8px;
    }

  }

  .Dropdown__title {
    width: 100%;
    padding: 3px;
    background-color: #323232;
    color: #fff;
    text-align: center;
  }
}
