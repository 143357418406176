@import 'view';
@import 'create';
@import 'money';

.campaign-index {
  padding: 32px;

  > div {
    margin-right: 16px;
    margin-left: 16px;
  }

  .campaign-create-btn {
    height: 36px;
    width: 80%;
    margin-bottom: 32px;
  }

  .campaign-status {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 2px solid $primary;
    border-radius: 8px;

    .campaign-status__item {
      padding: 4px;
      flex-grow: 1;
      flex-basis: 0;
      font-weight: bold;
      cursor: pointer;
      user-select: none;

      &.active {
        background-color: $primary;
        color: black;
      }
    }
  }
}

@media (max-width: 576px) {
  .campaign-index {
    padding: 16px;
    flex-direction: column;

    .campaign-create-btn {
      width: 100%;
      margin-bottom: 16px;
    }

    > div {
      margin: 0;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}
