@import 'globals/index';
.pointer {
  cursor: pointer;
}
.divider {
  height: 0;
  width: 100%;
  margin-bottom: 0;
  margin-top: 2rem;
  display: block;
  border-bottom: solid 2px $light;

  &.primary {
    border-color: $primary;
  }

  &.secondary {
    border-color: $secondary;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: none !important;
  background-color: transparent !important;
  background-clip: content-box !important;
}

.iconTips {
  height: calc(rem(46px) + 1vw);
  width: calc(rem(46px) + 1vw);

  @media (min-width: 991px){
    height: calc(rem(69px) + 1vw);
    width: calc(rem(69px) + 1vw);
  }
}

.menuLink.disabled, .menuLink.disabled:hover {
  background: rgb(0 0 0 / 0%) !important;
  color: gray !important;
  text-decoration: none !important;
  cursor: not-allowed;
}

.App .Global-btn-delete-dark {
  padding: 0.25rem 1.5rem;
  border-radius: 1rem;
  background-color: var(--bs-dark);
  color: var(--bs-primary);
  font-weight: 800;
  font-size: 12px;
}

.overflow-menu {

  @media (min-width: 1400px){
    max-height: 150px;
    min-height: 69px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(223, 223, 223);
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid var(--bs-orange);
    border-radius: 20px;
    background-color: var(--bs-orange);
  }

}

svg[data-testid="CheckBoxOutlineBlankIcon"] {
  color: #fff;
}

.MuiContainer-root .MuiBox-root {
  margin: 0;
}

.email-input {
  display: flex;
  flex-flow: column;
  text-align: left;

      input {
        height: 1.4375em;
        width: 100%;
        min-width: 0;
        margin: 0;
        padding: 4px 0 5px;
        display: block;
        box-sizing: content-box;
        border: none;
        border-color: var(--bs-orange);
        border-bottom: 2px solid;
        background: none;
        color: currentColor;
        font: inherit;
        letter-spacing: inherit;
        -webkit-animation-name: mui-auto-fill-cancel;
        animation-name: mui-auto-fill-cancel;
        -webkit-animation-duration: 10ms;
        animation-duration: 10ms;
        -webkit-tap-highlight-color: transparent;

        &:hover {
          border-color: var(--bs-orange);
        }

        &:focus {
          outline: none;
        }

      }

      span {
        margin-top: 8px;
        color: var(--bs-red);
        font-size: 0.75rem;
      }

}

.Thankyou-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .Thankyou-page__body {
    max-width: 485px;

    .Thankyou-page-icon svg {
      color: var(--bs-orange);
      font-size: 7rem;
    }
  }
}

.w-15 {
  width: 15%;
  text-align: left;
}

.campaign-create {
  .phone-input .MuiAvatar-root {
    height: 28px;
    width: 28px;
    margin-left: 0;
    cursor: pointer;
  }
}

.modal-fullscreen-md-down {
  max-width: 767px;
}

.new-upload__input {
  .image-crop-input {
    width: 100%;
    .image-crop-input__input {
      width: 100%;
      label {
        height: 153px;
        width: 100%;
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed #c7c3c3;
        background-color: #fff;
      }
    }
  }

  .btn-block {
    width: 100%;
  }
}

.btn-edit__venue {
  margin-top: -1.58rem;
  text-decoration: none;
  position: relative;

  &:hover {
    color: var(--bs-yellow);
  }
}

.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(126 124 124) !important;
}

@media (max-width: 767px) {

  .MuiCheckbox-root, .MuiFormControl-root {
    width: 100%;
    margin: 0 !important;
    margin-left: 0 !important;
  }

  .flex-mobile {
    .d-flex {
      flex-wrap: wrap;
      justify-content: flex-start !important;
    }

    .MuiCheckbox-root {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    }

    .w-15 {
      width: auto !important;
    }
  }

}


.Venues_container-special:last-child div {
  margin-right: 0 !important;
  margin-left: 0 !important;
}


.f-special-075rem {
  font-size: 0.78rem !important;
}

.banner__progress-overlay {
  width: 85%;
  margin: auto;
  position: static;
}

.avatar__progress-overlay {
  margin-left: -17px;
  margin-top: 11px;
  position: absolute;
}


.input-addon {
  position: relative;

  > span{
    padding: 0 10px;
    top: 0;
    position: absolute;
    color: var(--bs-orange);
    font-size: 24px;
  }
  
  > span + input{
    padding-left: 35px;
  }
}